import React from 'react';
import './navbar.css';

export default class Navbar_Back extends React.Component{
  render(){
    return (
      <div className="nav1">
         
      </div>
    );
  }
}