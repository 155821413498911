import './bottomline.css';
import React from 'react';

export default class Navbar extends React.Component{
    render(){
      return (
        <div className="bottomline">
            _
        </div>
      );
    }
  }